import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Home = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />
      <div className="flex flex-col">
        <div className="h-auto flex flex-col items-center pt-3 md:pt-24 md:flex-row md:justify-evenly">
          <div className="">
            <StaticImage
              className="bio-avatar dark:saturate-50 "
              formats={["auto", "webp", "avif"]}
              src="../images/profilepic.png"
              width={260}
              alt="Profile picture"
            />
          </div>
          <div className="md:self-center font-nunito p-3 w-2/3 lg:w-3/5">
            <h2 className="text-3xl md:text-6xl dark:text-slate-800">
              Hello, I'm{" "}
              <span className="text-stone-900 dark:text-fuchsia-900">
                Jason Fritsche
              </span>
            </h2>
            <h3 className="pt-6 text-xl md:text-2xl text-stone-700 dark:text-slate-900">
              I'm a web developer based in Austin, TX
            </h3>
            <h4 className="pt-6 text-lg md:text-xl w-8/12 md:w-1/2 text-stone-800 dark:text-slate-800">
              I am well versed in creating complex user interfaces using modern
              technologies. My experience varies far and wide, but building UI's
              (with Angular) is my primary skill. Please feel free to check out
              my blog and portfolio to see what I've been up to. Thanks for
              stopping by.
            </h4>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
